import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslatePipe } from '@app/pipes/translate.pipe';

import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    imports: [CommonModule, MatIconModule, TranslatePipe],
    selector: 'app-bragging-line',
    templateUrl: './bragging-line.component.html',
    styleUrls: ['./bragging-line.component.scss'],
    animations: [
        trigger('fadeTransition', [
            transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 })),
            ]),
        ]),
    ]
})
export class BraggingLineComponent implements OnInit, OnDestroy {
  @Input() seo_pages = false;
  currentUspIndex = 0; // Index of the currently displayed USP
  private uspCount = 3; // Total number of USPs
  private intervalId: any;
  isBrowser: boolean = false;
  innerWidth: number = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
      if (innerWidth < 480) {
        this.startUspRotation();
      }
    }
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startUspRotation() {
    this.intervalId = setInterval(() => {
      this.currentUspIndex = (this.currentUspIndex + 1) % this.uspCount;
    }, 3000); // Rotate USPs every 2 seconds
  }

  isActiveUsp(index: number): boolean {
    return this.currentUspIndex === index;
  }
}
